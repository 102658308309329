





















import Vue from "vue";
import { Component } from "vue-property-decorator";

import { api as auditApi } from "@/store/modules/audit";
import { FilterUiApi } from "@auditcloud/shared/lib/utils/filter/types";
import AItemFilterAction from "@auditcloud/components/widgets/sidebar/AItemFilterAction.vue";

@Component({
  components: {
    AItemFilterAction,
  },
})
export default class AAuditItemFilterAction extends Vue {
  readonly filterUIApi: FilterUiApi = {
    namespace: auditApi.namespace,
    getters: {
      getAggregations: auditApi.getters.getAuditItemListFilterAggregations,
      getFulltextSearch: auditApi.getters.getFullTextSearch,
    },
    mutations: {
      TOGGLE_FILTER: auditApi.mutations.TOGGLE_FILTER_SETTING,
      SET_FILTERS: auditApi.mutations.SET_FILTERS,
      CLEAR_FILTER_FOR_AGGREGATIONS:
        auditApi.mutations.CLEAR_FILTER_FOR_AGGREGATIONS,
      CLEAR_ALL_FILTERS: auditApi.mutations.CLEAR_FILTER_SETTINGS,
      SET_FULLTEXT_SEARCH: auditApi.mutations.SET_FULLTEXT_SEARCH,
    },
  };
}
